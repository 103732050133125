import { DEFAULT_LOCALE, LOCALES } from 'src/constants.cjs';
import { locales } from '../../locales';

export const defaultNS = 'common';
export const cookieName = 'i18next';

export function getOptions(lng = DEFAULT_LOCALE, ns = defaultNS) {
  return {
    // debug: true,
    supportedLngs: LOCALES,
    fallbackLng: DEFAULT_LOCALE,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
    resources: locales,
  };
}
