import(/* webpackMode: "eager", webpackExports: ["GtmHead","GtmScript"] */ "/home/runner/work/mm-js/mm-js/applications/content/app/[lang]/Gtm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/runner/work/mm-js/mm-js/applications/content/app/[lang]/Providers.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mm-js/mm-js/applications/content/src/favicons/android-chrome-192x192.png");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mm-js/mm-js/applications/content/src/favicons/mstile-144x144.png");
;
import(/* webpackMode: "eager", webpackExports: ["WebVitals"] */ "/home/runner/work/mm-js/mm-js/applications/content/src/ReportWebVitals.ts");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mm-js/mm-js/design/ragnar-tailwind-config/globals.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mm-js/mm-js/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["MazeScript"] */ "/home/runner/work/mm-js/mm-js/packages/maze/src/MazeScript.tsx");
